import '@fancyapps/ui/dist/fancybox/fancybox.css';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import '../styles/pages/Gallery.scss';
import exampleVideo from '../videos/example.mp4';

function Gallery() {
	const [isHovered, setIsHovered] = useState(false);

	const exampleVideoYoutube = 'https://www.youtube.com/embed/acHUC5uKiRo';

	return (
		<section className='gallery'>
			<a href='/' className='nav'>
				<div className='nav-title'>
					<a href='/'>
						<p>{'<'}</p>
					</a>
					<a href='/'>
						<h4>Gallery</h4>
					</a>
				</div>
			</a>

			<div className='gallery-videos'>
				<div className='grid-container'>
					<div
						className='grid-item'
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
					>
						<ReactPlayer
							url={exampleVideoYoutube}
							playing={isHovered}
							controls={true}
							width='100%'
							height='100%'
							className='video-player'
						/>
					</div>

					<div
						className='grid-item'
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
					>
						<ReactPlayer
							url={exampleVideoYoutube}
							playing={isHovered}
							controls={true}
							width='100%'
							height='100%'
							className='video-player'
						/>
					</div>
				</div>
			</div>
			{/* <div>
				<h1>Example Video Using YouTube</h1>
				<div
					style={{
						width: '600px',
						height: '338px',
						margin: '0 auto 100px auto',
					}}
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}
				>
					<ReactPlayer
						url={exampleVideoYoutube}
						playing={isHovered}
						controls={isHovered}
						width='100%'
						height='100%'
					/>
				</div>
			</div> */}
		</section>
	);
}

export default Gallery;
