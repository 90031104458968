import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Home, Gallery } from './pages';
import './styles/index.scss'

const router = createBrowserRouter([
	{
		path: '/',
		element: <Home />,
	},
	{
		path: '/gallery',
		element: <Gallery />,
	},
]);

function App() {
	return <RouterProvider router={router} />;
}

export default App;
