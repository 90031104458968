import { useEffect, useState } from 'react';
import gsap from 'gsap';
import foto1 from '../img/foto1.jpg';
import foto2 from '../img/foto2.jpg';
import foto3 from '../img/foto3.jpg';
import foto4 from '../img/foto4.jpg';
import foto5 from '../img/foto5.jpg';
import profile from '../img/profile.jpeg';
import ft1 from '../img/ft1.webp';
import ft2 from '../img/ft2.webp';
import ft3 from '../img/ft3.webp';
import ft4 from '../img/ft4.webp';
import ft5 from '../img/ft5.webp';
import ft6 from '../img/ft6.webp';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import '../styles/pages/Home.scss';
import ReactPlayer from 'react-player';

gsap.registerPlugin(ScrollTrigger);

function App() {
	Fancybox.bind('[data-fancybox]', {
		// Your options go here
	});

	const [photos, setPhotos] = useState([]);
	const [content, setContent] = useState([]);

	const [isHovered, setIsHovered] = useState(false);

	const exampleVideoYoutube = 'https://www.youtube.com/embed/acHUC5uKiRo';

	const handleVideoHover = (index) => {
		setIsHovered(index);
	};

	const fetchContent = async () => {
		try {
			const response = await fetch(
				'https://rikiiinsadmin.web.id/api/contents/'
			);
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			const data = await response.json();
			setContent(data);
			console.log('ini data content : ', data);
		} catch (error) {
			console.error('Error fetching data content:', error);
		}
	};

	useEffect(() => {
		fetchContent();
	}, []);

	return (
		<section className='home'>
			<div className='header'>
				<div className='profile'>
					<img
						className='profile-img'
						src={profile}
						alt='Riki Noval Suherman'
					/>
					<h4 className='title'>Riki Noval Suherman</h4>
					<p className='jobdesc'>
						Freelance Photography & Videography
					</p>
					<p className='location'>Jakarta, Indonesia</p>
				</div>

				<div className='contact'>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span>
						<a
							href='https://www.instagram.com/rikiiiiins/'
							target='_blank'
							rel='noreferrer'
						>
							<i className='fa-brands fa-instagram fa-2xl'></i>
						</a>
					</span>
					<span>
						<a
							href='https://www.tiktok.com/@rikinovalsuherman/'
							target='_blank'
							rel='noreferrer'
						>
							<i className='fa-brands fa-tiktok fa-2xl'></i>
						</a>
					</span>
					<span>
						<a
							href='https://wa.me/6285951438257'
							target='_blank'
							rel='noreferrer'
						>
							<i className='fa-brands fa-whatsapp fa-2xl'></i>
						</a>
					</span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
				</div>
			</div>
			&nbsp;
			<section className='gallery'>
				<div className='gallery-title'>
					<p>Gallery</p>
				</div>
				<hr className='gallery-line' />

				<div className='gallery-videos'>
					<div className='grid-container'>
						{content.length > 0 ? (
							content.map((data) => {
								const { type, title, content } = data;

								return (
									<div key={title} className='grid-item'>
										{type === 'video' ? (
											<ReactPlayer
												onMouseEnter={() =>
													handleVideoHover(title)
												}
												onMouseLeave={() =>
													handleVideoHover(null)
												}
												url={content}
												playing={isHovered === title}
												controls={true}
												width='100%'
												height='100%'
												className='video-player'
											/>
										) : (
											<img
												src={content}
												alt={title}
												className='grid-image'
											/>
										)}
									</div>
								);
							})
						) : (
							<div className='loader'></div>
						)}
					</div>
				</div>
			</section>
		</section>
	);
}

export default App;
